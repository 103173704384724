/**
 * Created by xxh on 2022/11/25.
 */
export const API_ROOT =
  (process.env.NODE_ENV === 'production')
  ?  (document.location.protocol + '//' + document.location.host + '/')
    : 'http://192.168.3.90:88/'
export const IMAGE_ROOT =
  (process.env.NODE_ENV === 'production')
  ? (document.location.protocol + '//' + document.location.host + '/')
      : 'http://192.168.3.90:88/'
