<template>
  <div style="position: relative;" v-if="field.Visable">
    <div class="myInput report-v2">
      <van-cell-group :style="'background-color: ' + config.backColor">
        <van-field v-if="!isList" :required="field.Nullable == true"
                   :label-width="config.labelWidth" :style="'height: ' + config.cellHeight + 'px; line-height: ' + config.cellHeight + 'px;background-color: ' + config.backColor"  >
          <template slot="label">
            <div v-if="config.labelWidth !== '0'" :style="'width: ' + config.labelWidth + 'px; color: ' + pageSetup.wordColor +'; font-weight: ' + weightList[pageSetup.wordWeight] + '; font-size: ' + sizeList[pageSetup.wordSize] + 'px; height: ' +
            config.cellHeight + 'px; line-height: ' + config.cellHeight + 'px;'">{{ config.name }}
              </div>
          </template>
          <template slot="input">
            <input v-if="config.rows < 2" :placeholder="config.description"  :rows="config.rows" :type="config.type"
                   v-model="dataSet[tablecode][rowindex][config.FieldCode]"
                   @change="changeV" @focus="editFieldBefor"
                   :disabled="isEditSaved || !field.Editable || config.audioToText"
                   :style="'width:100%; color: ' + pageSetup.inputColor + ' font-weight: ' + weightList[pageSetup.inputWeight] + '; font-size: ' + sizeList[pageSetup.inputSize] + 'px; background-color: ' + config.backColor + '; height: ' +
          config.cellHeight + 'px; line-height: ' +  config.cellHeight
              + 'px; '" />
            <textarea v-else :placeholder="config.description"  :rows="config.rows" :type="config.type"
                      v-model="dataSet[tablecode][rowindex][config.FieldCode]"
                      @change="changeV" @focus="editFieldBefor"
                      :disabled="isEditSaved || !field.Editable || config.audioToText"
                      :style="'width:100%; color: ' + pageSetup.inputColor + ' font-weight: ' + weightList[pageSetup.inputWeight] + '; font-size: ' + sizeList[pageSetup.inputSize] + 'px;  background-color: ' + config.backColor + '; height: ' +
          config.cellHeight + 'px; line-height: ' +  (config.cellHeight/config.rows - 5)
              + 'px; '" />
            <voice-input-button
                    v-if="config.audioToText"
                    v-model="dataSet[tablecode][rowindex][config.FieldCode]"
                    @record="showResult"
                    @record-start="recordStart"
                    @record-stop="recordStop"
                    @record-blank="recordNoResult"
                    @record-failed="recordFailed"
                    @record-ready="recordReady"
                    @record-complete="recordComplete"
                    interactiveMode="touch"
                    color="#000"
                    tipPosition="top"
            >
              <template slot="no-speak">没听清您说的什么</template>
            </voice-input-button>
          </template>
          <template #button>
            <van-button v-if="!isEditSaved && field.Editable && field.QRCoder !== null"  size="small" type="default"  style="" @click="sgBarCodeV2" :icon="require('@/assets/images/report/barcode.png')"></van-button>
            <!--<div v-if="!isEditSaved && field.Editable && field.QRCoder !== null"  size="small" type="default"  style="" @click="sgBarCodeV2">ss</div>-->
            <van-button v-if="field.Linkers != null  && field.Linkers.length > 0"  type="default" style="width: 12px;height: 12px;" :icon="require('@/assets/images/report/linker.png')" @click="linkReport()"></van-button>
          </template>
        </van-field>
      </van-cell-group>
    </div>
    <van-field v-if="isList && config.type === 'text'"  :rows="config.rows" :type="config.type"  :label-width="config.labelWidth" :style="'height: ' + config.cellHeight + 'px; line-height: ' + config.cellHeight + 'px;'" >
      <template slot="label">
        <div v-if="config.labelWidth !== '0'" :style="'width: ' + config.labelWidth + 'px; color: ' + config.wordColor +'; font-weight: ' + config.wordWeight + '; font-size: ' + config.wordSize + 'px; height: ' +
             (config.cellHeight - 20) + 'px; line-height: ' + (config.cellHeight - 20) + 'px;'">{{ config.name + ':' }}</div>
      </template>
      <!--color: ' + config.wordColor +';-->
      <template slot="input">
        <div :style="'font-weight: ' + config.wordWeight + '; font-size: ' + config.wordSize + 'px;  height: ' +
            (config.cellHeight - 20) + 'px; line-height: ' + (config.cellHeight - 20) + 'px; margin-top:' + (config.cellHeight < 48 ? (config.cellHeight - 45) : 0) + 'px'">{{ dataRow['app'][field.FieldCode] }}</div>
      </template>
    </van-field>
    <van-field v-if="isList && config.type === 'textarea'"  :rows="config.rows" :type="config.type"  :label-width="config.labelWidth" >
      <template slot="label">
        <div v-if="config.labelWidth !== '0'" :style="'width: ' + config.labelWidth + 'px; color: ' + config.wordColor +'; font-weight: ' + config.wordWeight + '; font-size: ' + config.wordSize + 'px;  height: ' +
             (config.cellHeight - 20) + 'px; line-height: ' + (config.cellHeight - 20) + 'px;'">{{ config.name + ':' }}</div>
      </template>
      <!--color: ' + config.wordColor +';-->
      <template slot="input">
        <div :style="'width:100%;   word-wrap: break-word; overflow-y:auto;  font-weight: ' + config.wordWeight + '; font-size: ' + config.wordSize + 'px;  height: ' +
             config.cellHeight + 'px;; margin-top:' + (config.cellHeight < 48 ? (config.cellHeight - 45) : 0) + 'px'">{{ dataRow['app'][field.FieldCode] }}</div>
      </template>
    </van-field>
  </div>
</template>

<script>
import { Field, Button, CellGroup } from 'vant'
import Recorder from 'js-audio-recorder'
export default {
    name: 'nxMobileInput',
    components: {
        [Field.name]: Field,
        [CellGroup.name]: CellGroup,
        [Button.name]: Button

    },
    props: {       pageSetup: Object,
        mastertable: Object,
        tables: {
            type: Array,
            default: ([])
        },
        dataSet: Object,
        config: Object,
        label: String,
        isEditSaved: Boolean,
        rowindex: Number,
        tablecode: String,
        editFieldBefore: Function,
        editFieldBeforeV2: Function,
        editFieldAfter: Function,
        linkReportV2: Function,
        dataRow: Object,
        changeValue: Function,
        isList: {
            type: Boolean,
            default: false
        },
        sgBarCode: Function,
        updateField: Boolean
    },
    data () {
      return {
          weightList: {                 0: 'inherit',                 33: 'normal',                 66: 'bold',                 99: 'bolder'             },             sizeList: {                 0: '10',                 33: '14',                 66: '16',                 99: '20'             },             field: null
      }
    },
    watch: {
        updateField () {
            let tableList = []
            tableList = this.$utils.deepClone(this.tables)
            tableList.push(this.mastertable)
            this.field = null
            for (let i = 0; i < tableList.length; i++) {
                const table = tableList[i]
                for (let j = 0; j < table.Fields.length; j++) {
                    if ((this.tablecode === undefined || table.TableCode === this.tablecode) && table.Fields[j].FieldCode === this.config.FieldCode) {
                        this.field = table.Fields[j]
                    }
                }
                if (this.field !== null) {
                    break
                }
            }
        }
    },
    mounted () {
        let tableList = []
        tableList = this.$utils.deepClone(this.tables)
        tableList.push(this.mastertable)
        for (let i = 0; i < tableList.length; i++) {
            const table = tableList[i]
            for (let j = 0; j < table.Fields.length; j++) {
                if ((this.tablecode === undefined || table.TableCode === this.tablecode) && table.Fields[j].FieldCode === this.config.FieldCode) {
                    this.field = table.Fields[j]
                }
            }
            if (this.field !== null) {
                break
            }
        }
        // console.log(tableList)
        // console.log(this.tablecode)
        // console.log(this.config.FieldCode)
        // console.log(this.field)
        // console.log(this.config.field)
        if (!this.isList && this.config.audioToText) {
            Recorder.getPermission().then(() => {
                console.log('录音给权限了');
            }, (error) => {
                console.log(`${error.name} : ${error.message}`)
            })
        }
    },
    methods:{
        recordReady() {
            console.info("按钮就绪!")
        },
        recordStart() {
            console.info("录音开始")
        },
        showResult(text) {
            this.editFieldBefore(this.tablecode, this.rowindex, this.config.FieldCode)
            this.changeValue(this.tablecode, this.rowindex, this.config.FieldCode, text)
            console.info("收到识别结果：", text)
        },
        recordStop() {
            console.info("录音结束")
        },
        recordNoResult() {
           this.$alert('没有录到什么，请重试')
        },
        recordComplete(text) {
            this.editFieldBefore(this.tablecode, this.rowindex, this.config.FieldCode)
            this.changeValue(this.tablecode, this.rowindex, this.config.FieldCode, text)
            console.info('识别完成! 最终结果：', text)
        },
        recordFailed(error) {
            console.info('识别失败，错误栈：', error)
        },
        changeV () {
            this.editFieldBefore(this.tablecode, this.rowindex, this.config.FieldCode)
            this.editFieldAfter()
        },
        editFieldBefor () {
            this.editFieldBefore(this.tablecode, this.rowindex, this.config.FieldCode)
            this.editFieldBeforeV2()
        },
        linkReport () {
            this.linkReportV2(this.tablecode, this.config.FieldCode, this.rowindex)
        },
        sgBarCodeV2 () {
            this.editFieldBefore(this.tablecode, this.rowindex, this.config.FieldCode)
            this.sgBarCode()
        }
    }
}
</script>

<style scoped lang="less">
</style>
